import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Alert,
  Chip,
  Snackbar,
  CircularProgress,
  IconButton,
  Button,
} from '@mui/material';
import { WhatsApp, AddCircleOutline } from '@mui/icons-material';
import { shopperRequestAds } from '../services/api';

interface LocationState {
  success?: boolean;
  message?: string;
}

interface ShopperRequestAd {
  _id: string;
  itemTypes: string[];
  weight: number;
  route: string;
  departureCity: string;
  arrivalCity: string;
  pricePerKg: number;
  totalPrice: number;
  currency: string;
  status: string;
  createdAt: string;
  user: {
    whatsappNumber: string;
  };
}

const formatPrice = (price: number) => {
  return new Intl.NumberFormat('id-ID').format(price);
};

const ShopperRequestAds: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [requests, setRequests] = useState<ShopperRequestAd[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const response = await shopperRequestAds.getAll();
        if (Array.isArray(response)) {
          setRequests(response);
        } else {
          console.error('Invalid response format:', response);
          setError('Format data tidak valid');
        }
      } catch (err) {
        console.error('Error fetching requests:', err);
        setError('Gagal memuat data permintaan');
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, []);

  useEffect(() => {
    const state = location.state as LocationState;
    if (state?.success) {
      setSnackbarMessage(state.message || 'Permintaan belanja berhasil dibuat');
      setOpenSnackbar(true);
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container sx={{ py: 4 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant="h4">
          Permintaan Jastip Bagasi
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to="/create-shopper-request"
          startIcon={<AddCircleOutline />}
        >
          Butuh Jastip
        </Button>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : requests.length === 0 ? (
        <Alert severity="info">Belum ada permintaan jastip bagasi</Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Rute</TableCell>
                <TableCell>Jenis Barang</TableCell>
                <TableCell>Berat</TableCell>
                <TableCell>Bayaran</TableCell>
                <TableCell>Aksi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requests.map((request) => (
                <TableRow key={request._id} hover>
                  <TableCell>
                    {request.departureCity} → {request.arrivalCity}
                  </TableCell>
                  <TableCell>
                    <Box display="flex" gap={1} flexWrap="wrap">
                      {request.itemTypes?.map((type) => (
                        <Chip key={type} label={type} size="small" />
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell>{request.weight} KG</TableCell>
                  <TableCell>{request.currency} {formatPrice(request.totalPrice)}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => navigate(`/shopper-requests/${request._id}`)}
                    >
                      <WhatsApp />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity="success"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ShopperRequestAds;
