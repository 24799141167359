import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  CircularProgress,
  Alert,
  Chip,
  Divider,
  IconButton,
  Button,
  Rating,
  Avatar,
  Tooltip,
  Modal,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { WhatsApp, VerifiedUser, Close } from '@mui/icons-material';
import { format } from 'date-fns';
import { id as idLocale } from 'date-fns/locale';
import { shopperRequestAds } from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

interface ShopperRequestDetail {
  _id: string;
  itemTypes: string[];
  additionalNotes: string;
  images: string[];
  weight: number;
  route: string;
  departureCity: string;
  arrivalCity: string;
  pricePerKg: number;
  totalPrice: number;
  currency: string;
  expiresAt: string;
  status: string;
  createdAt: string;
  user: {
    name: string;
    whatsappNumber?: string;
    rating?: number;
    totalReviews?: number;
    reviews?: any[];
    avatar?: string;
    username?: string;
    isVerified: boolean;
  };
}

const formatDateInIndonesian = (dateString: string) => {
  const date = new Date(dateString);
  return format(date, 'd MMMM yyyy', { locale: idLocale });
};

const formatPrice = (price: number) => {
  return new Intl.NumberFormat('id-ID').format(price);
};

const ShopperRequestAdDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [request, setRequest] = useState<ShopperRequestDetail | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [whatsappError, setWhatsappError] = useState<string | null>(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await shopperRequestAds.getById(id!);
        setRequest(response);
      } catch (err) {
        setError('Permintaan tidak ditemukan');
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [id]);

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getGalleryImages = (images: string[]): ReactImageGalleryItem[] => {
    return images.map(image => ({
      original: image,
      thumbnail: image,
      originalClass: isMobile ? 'mobile-gallery-image' : 'desktop-gallery-image',
      thumbnailClass: 'gallery-thumbnail',
      renderItem: (item) => (
        <div style={{ 
          height: isMobile ? 300 : 500,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <img 
            src={item.original} 
            alt={item.description || ''}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain'
            }}
          />
        </div>
      )
    }));
  };

  const handleWhatsAppClick = () => {
    setWhatsappError(null);

    // If not logged in, redirect to login
    if (!user) {
      navigate('/login', { state: { from: `/shopper-request-ads/${id}` } });
      return;
    }

    // If not a traveler, show error
    if (user.role !== 'traveler') {
      setWhatsappError('Anda harus terdaftar sebagai traveler untuk menghubungi pembeli');
      return;
    }

    // If not verified and whatsapp is not available, show error
    if (!user.isVerified && !request?.user?.whatsappNumber) {
      setWhatsappError('Anda harus memverifikasi akun untuk menghubungi pembeli');
      return;
    }

    if (!request?.user?.whatsappNumber) {
      setWhatsappError('Nomor WhatsApp tidak tersedia');
      return;
    }
    
    const message = encodeURIComponent(
      `Hi, saya tertarik dengan permintaan jastip Anda untuk ${request.itemTypes.join(', ')} dari ${request.departureCity} ke ${request.arrivalCity}`
    );
    window.open(`https://wa.me/${request.user.whatsappNumber}?text=${message}`, '_blank');
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error || !request) {
    return (
      <Container sx={{ py: 4 }}>
        <Alert severity="error">{error || 'Data tidak ditemukan'}</Alert>
      </Container>
    );
  }

  return (
    <>
      {request && (
        <Helmet>
          <title>Butuh Jastip: {request.itemTypes.join(', ')} dari {request.departureCity} ke {request.arrivalCity} | Bagasi</title>
          <meta name="description" content={`Butuh jastip ${request.itemTypes.join(', ')} dari ${request.departureCity} ke ${request.arrivalCity}. Berat ${request.weight}KG, bayaran ${request.currency} ${formatPrice(request.totalPrice)}. ${request.additionalNotes}`} />
          
          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`Butuh Jastip: ${request.itemTypes.join(', ')} dari ${request.departureCity} ke ${request.arrivalCity}`} />
          <meta property="og:description" content={`Butuh jastip ${request.itemTypes.join(', ')} dari ${request.departureCity} ke ${request.arrivalCity}. Berat ${request.weight}KG, bayaran ${request.currency} ${formatPrice(request.totalPrice)}`} />
          {request.images[0] && <meta property="og:image" content={request.images[0]} />}
          
          {/* Twitter */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={`Butuh Jastip: ${request.itemTypes.join(', ')} dari ${request.departureCity} ke ${request.arrivalCity}`} />
          <meta name="twitter:description" content={`Butuh jastip ${request.itemTypes.join(', ')} dari ${request.departureCity} ke ${request.arrivalCity}. Berat ${request.weight}KG, bayaran ${request.currency} ${formatPrice(request.totalPrice)}`} />
          {request.images[0] && <meta name="twitter:image" content={request.images[0]} />}
          
          {/* Additional Meta */}
          <meta name="keywords" content={`jastip, ${request.itemTypes.join(', ')}, ${request.departureCity}, ${request.arrivalCity}, titip barang, bagasi`} />
          <meta name="author" content={request.user.name || request.user.username} />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href={window.location.href} />
          
          {/* Schema.org markup */}
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Product",
              "name": `Jastip ${request.itemTypes.join(', ')}`,
              "description": `Permintaan jastip ${request.itemTypes.join(', ')} dari ${request.departureCity} ke ${request.arrivalCity}`,
              "image": request.images,
              "offers": {
                "@type": "Offer",
                "price": request.totalPrice,
                "priceCurrency": request.currency,
                "availability": request.status === 'active' ? "https://schema.org/InStock" : "https://schema.org/OutOfStock",
                "priceValidUntil": request.expiresAt
              },
              "seller": {
                "@type": "Person",
                "name": request.user.name || request.user.username,
                "identifier": request.user.username
              },
              "review": request.user.reviews?.map((review: any) => ({
                "@type": "Review",
                "reviewRating": {
                  "@type": "Rating",
                  "ratingValue": review.rating,
                  "bestRating": "5",
                  "worstRating": "1"
                },
                "author": {
                  "@type": "Person",
                  "name": review.user?.username || "Anonymous"
                }
              })) || [],
              "aggregateRating": request.user.rating ? {
                "@type": "AggregateRating",
                "ratingValue": request.user.rating,
                "reviewCount": request.user.totalReviews || 0,
                "bestRating": "5",
                "worstRating": "1"
              } : undefined
            })}
          </script>
        </Helmet>
      )}
      <Container maxWidth="lg" sx={{ py: 4 }}>
        {whatsappError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {whatsappError}
          </Alert>
        )}
        <Paper sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {/* Header with Route */}
              <Box mb={2}>
                <Typography variant="h5" gutterBottom>
                  Detail Permintaan Jastip
                </Typography>
                <Typography variant="h6" color="primary">
                  {request.departureCity} → {request.arrivalCity}
                </Typography>
              </Box>

              <Divider sx={{ my: 2 }} />

              {/* Shopper Details */}
              <Box display="flex" alignItems="flex-start" gap={2} mb={3} flexDirection={{ xs: 'column', sm: 'row' }}>
                <Box display="flex" alignItems="center" gap={2} flex={1}>
                  <Avatar
                    src={request.user.avatar}
                    alt={request.user.name}
                    sx={{ width: 56, height: 56 }}
                  />
                  <Box>
                    <Typography variant="h6" gutterBottom>{request.user.name}</Typography>
                    <Box display="flex" alignItems="center" gap={1} sx={{ mb: 1 }}>
                      <Typography variant="h6" color="primary" sx={{ m: 0 }}>
                        {request.user.username}
                      </Typography>
                      {request.user.isVerified && (
                        <Tooltip title="Akun Terverifikasi">
                          <VerifiedUser color="primary" fontSize="small" sx={{ mt: '2px' }} />
                        </Tooltip>
                      )}
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Rating value={request.user.rating || 0} readOnly precision={0.1} size="small" />
                      <Typography variant="body2" color="text.secondary">
                        {request.user.rating?.toFixed(1) || '0.0'} ({request.user.totalReviews || 0} ulasan)
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box 
                  display="flex" 
                  justifyContent={{ xs: 'flex-start', sm: 'flex-end' }} 
                  flex={1}
                  mt={{ xs: 1, sm: 0 }}
                  ml={{ xs: 0, sm: 0 }}
                  pb={{ xs: 2, sm: 0 }}
                >
                  <Tooltip title={
                    !user ? "Login untuk menghubungi pembeli" :
                    user.role !== 'traveler' ? "Anda harus terdaftar sebagai traveler" :
                    !user.isVerified && !request.user.whatsappNumber ? "Verifikasi akun untuk menghubungi pembeli" :
                    !request.user.whatsappNumber ? "Nomor WhatsApp tidak tersedia" :
                    "Hubungi via WhatsApp"
                  }>
                    <span>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<WhatsApp />}
                        onClick={handleWhatsAppClick}
                        fullWidth={false}
                        sx={{ 
                          minWidth: 200,
                          alignSelf: { xs: 'flex-start', sm: 'center' }
                        }}
                      >
                        Hubungi Pembeli
                      </Button>
                    </span>
                  </Tooltip>
                </Box>
              </Box>

              {/* Item Types */}
              <Box display="flex" gap={1} flexWrap="wrap" mb={2}>
                {request.itemTypes.map((type) => (
                  <Chip key={type} label={type} />
                ))}
              </Box>

              {/* Main Info with Highlighted Payment */}
              <Paper elevation={3} sx={{ p: 2, pl: 4, mb: 3, bgcolor: '#f5f5f5' }}>
                <Typography variant="h6" color="primary" sx={{ mb: 0.5 }}>
                  Total Bayaran
                </Typography>
                <Typography variant="h4" color="primary" sx={{ mb: 0.5 }}>
                  {request.currency} {formatPrice(request.totalPrice)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  *Dibayar langsung dari pihak penitip
                </Typography>
              </Paper>

              {/* Additional Info */}
              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" gutterBottom>
                    <strong>Berat:</strong> {request.weight} KG
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>Batas Waktu:</strong> {formatDateInIndonesian(request.expiresAt)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" gutterBottom>
                    <strong>Dibuat:</strong> {formatDateInIndonesian(request.createdAt)}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>Status:</strong> {request.status}
                  </Typography>
                </Grid>
              </Grid>

              <Divider sx={{ my: 2 }} />

              {/* Notes */}
              <Typography variant="h6" gutterBottom>
                Catatan Tambahan
              </Typography>
              <Typography variant="body1" paragraph sx={{ whiteSpace: 'pre-line' }}>
                {request.additionalNotes || '-'}
              </Typography>

              <Divider sx={{ my: 2 }} />

              {/* Images */}
              <Typography variant="h6" sx={{ mb: 1 }}>
                Foto Barang
              </Typography>
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                {request.images.map((image, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: { xs: '100%', sm: '200px' },
                      height: { xs: '200px', sm: '200px' },
                      position: 'relative',
                      cursor: 'pointer',
                      borderRadius: '5px',
                      overflow: 'hidden',
                      '&:hover': {
                        opacity: 0.9,
                      },
                    }}
                    onClick={() => handleImageClick(index)}
                  >
                    <img
                      src={image}
                      alt={`Barang ${index + 1}`}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: '20px',
                      }}
                    />
                  </Box>
                ))}
              </Box>

              {/* Image Gallery Modal */}
              <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(0, 0, 0, 0.9)',
                  },
                }}
              >
                <Box sx={{ 
                  position: 'relative',
                  width: '100%',
                  maxWidth: '1000px',
                  margin: '20px',
                  outline: 'none',
                }}>
                  <IconButton
                    onClick={handleCloseModal}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: 'white',
                      zIndex: 1,
                    }}
                  >
                    <Close />
                  </IconButton>
                  <ImageGallery
                    items={getGalleryImages(request.images)}
                    startIndex={selectedImageIndex}
                    showPlayButton={false}
                    showFullscreenButton={true}
                    showNav={true}
                    showThumbnails={true}
                    useBrowserFullscreen={true}
                    slideDuration={300}
                    additionalClass="custom-image-gallery"
                    swipeThreshold={30}
                    useTranslate3D={true}
                  />
                </Box>
              </Modal>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default ShopperRequestAdDetails;
