import { Ad } from '../types';

export const generateAdUrl = (ad: Ad) => {
  const departureCity = ad.departureCity.toLowerCase().replace(/ /g, '-');
  const arrivalCity = ad.arrivalCity.toLowerCase().replace(/ /g, '-');
  const slug = `jastip-${departureCity}-${arrivalCity}`;
  const date = new Date(ad.departureDate).toLocaleDateString('id-ID', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  }).toLowerCase().replace(/ /g, '-');
  return `/ads/${slug}/${date}/${ad._id}`;
};
