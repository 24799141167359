import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  Button,
  Paper,
  Grid,
  IconButton,
  Alert,
  InputLabel,
  CircularProgress
} from '@mui/material';
import { PhotoCamera, Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { shopperRequestAds } from '../services/api';
import { uploadImages } from '../services/imageService';

const ITEM_TYPES = [
  'Pakaian',
  'Makanan & Snack',
  'Obat-obatan',
  'Elektronik',
  'Dokumen',
  'Lainnya'
];

const INDONESIA_CITIES = ['Jakarta', 'Surabaya', 'Bandung', 'Medan', 'Semarang', 'Pontianak', 'Denpasar', 'Tangerang', 'Bekasi', 'Malang', 'Bogor', 'Yogyakarta'];
const AUSTRALIA_CITIES = ['Sydney', 'Melbourne', 'Brisbane', 'Perth', 'Adelaide'];

const CreateShopperRequestAd = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    itemTypes: [] as string[],
    additionalNotes: '',
    weight: 1,
    route: 'australia-indonesia',
    departureCity: '',
    arrivalCity: '',
    expiresAt: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000).toISOString().split('T')[0] // 2 weeks from now
  });
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadedImageUrls, setUploadedImageUrls] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [uploadingImages, setUploadingImages] = useState<{ [key: number]: boolean }>({});

  // Check user role and membership
  if (user?.role !== 'shopper') {
    return (
      <Container maxWidth="sm" sx={{ mt: 4, textAlign: 'center' }}>
        <Alert severity="warning" sx={{ mb: 3 }}>
          Fitur ini hanya tersedia untuk akun shopper. Silakan daftar sebagai shopper untuk mengakses fitur ini.
        </Alert>
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            onClick={() => navigate('/register?role=shopper')}
            sx={{ mr: 2 }}
          >
            Daftar sebagai Shopper
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate('/')}
          >
            Kembali ke Beranda
          </Button>
        </Box>
      </Container>
    );
  }

  if (!user.membership?.type || user.membership.type === 'none' || 
      (user.membership.expiresAt && new Date(user.membership.expiresAt) < new Date())) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4, textAlign: 'center' }}>
        <Alert severity="warning" sx={{ mb: 3 }}>
          Kamu memerlukan membership aktif untuk membuat permintaan belanja.
        </Alert>
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            onClick={() => navigate('/membership')}
            sx={{ mr: 2 }}
          >
            Lihat Membership
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate('/')}
          >
            Kembali ke Beranda
          </Button>
        </Box>
      </Container>
    );
  }

  const handleItemTypeChange = (type: string) => {
    setFormData(prev => ({
      ...prev,
      itemTypes: prev.itemTypes.includes(type)
        ? prev.itemTypes.filter(t => t !== type)
        : [...prev.itemTypes, type]
    }));
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;

    const newFiles = Array.from(files);
    if (selectedFiles.length + newFiles.length > 5) {
      setError('Maksimal 5 foto yang dapat diunggah');
      return;
    }

    try {
      const nextIndex = selectedFiles.length;
      setUploadingImages(prev => ({ ...prev, [nextIndex]: true }));
      const token = localStorage.getItem('token') || '';
      
      // Add files to state first for UI preview
      setSelectedFiles(prev => [...prev, ...newFiles]);
      
      // Then upload and get URLs
      const urls = await uploadImages(newFiles, token);
      setUploadedImageUrls(prev => [...prev, ...(Array.isArray(urls) ? urls : [])]);
    } catch (error: any) {
      console.error('Error uploading images:', error);
      setError(error.message || 'Gagal mengunggah foto. Silakan coba lagi.');
      
      // Remove the files from preview if upload failed
      setSelectedFiles(prev => prev.slice(0, -newFiles.length));
    } finally {
      const nextIndex = selectedFiles.length;
      setUploadingImages(prev => {
        const newState = { ...prev };
        delete newState[nextIndex];
        return newState;
      });
    }
  };

  const removeImage = (index: number) => {
    setSelectedFiles(prev => prev.filter((_, i) => i !== index));
    setUploadedImageUrls(prev => prev.filter((_, i) => i !== index));
  };

  const handleInputChange = (field: string, value: string | number) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const calculatePrice = () => {
    const basePrice = formData.route === 'indonesia-australia' ? 150000 : 195000;
    return basePrice * formData.weight;
  };

  const formatPrice = (price: number) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(price);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    // Validate required fields
    if (!formData.itemTypes.length) {
      setError('Pilih minimal satu jenis barang');
      setLoading(false);
      return;
    }

    if (!formData.route || !formData.departureCity || 
        !formData.arrivalCity || !formData.additionalNotes || !formData.expiresAt) {
      setError('Semua field harus diisi');
      setLoading(false);
      return;
    }

    if (!uploadedImageUrls.length) {
      setError('Upload minimal satu foto');
      setLoading(false);
      return;
    }

    try {
      // Format the data according to backend requirements
      const requestData = {
        ...formData,
        images: uploadedImageUrls
      };

      // Create the ad
      await shopperRequestAds.create(requestData);
      
      // Navigate to shopper requests with success state
      navigate('/shopper-requests', { 
        state: { 
          success: true,
          message: 'Permintaan belanja berhasil dibuat' 
        } 
      });
    } catch (error: any) {
      console.error('Error creating shopper request ad:', error);
      const errorMessage = error.response?.data?.message || 'Gagal membuat permintaan belanja. Silakan coba lagi.';
      setError(errorMessage);
      
      // If there's a redirect URL in the error response, navigate to it
      if (error.response?.data?.redirectTo) {
        navigate(error.response.data.redirectTo, {
          state: {
            error: errorMessage,
            returnTo: '/create-shopper-request'
          }
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Buat Permintaan Jastip Bagasi
        </Typography>

        <form onSubmit={handleSubmit}>
          <FormControl component="fieldset" fullWidth sx={{ mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Jenis Barang
            </Typography>
            <FormGroup>
              <Grid container spacing={1}>
                {ITEM_TYPES.map(type => (
                  <Grid item xs={6} sm={4} key={type}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.itemTypes.includes(type)}
                          onChange={() => handleItemTypeChange(type)}
                        />
                      }
                      label={type}
                    />
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
          </FormControl>

          <TextField
            fullWidth
            multiline
            rows={4}
            label="Catatan Tambahan"
            value={formData.additionalNotes}
            onChange={(e) => handleInputChange('additionalNotes', e.target.value)}
            sx={{ mb: 3 }}
          />

          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Foto Barang
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Format foto harus .jpg jpeg png dan <strong>ukuran min. 300 x 300 px</strong> (untuk gambar optimal, gunakan ukuran min. 1200 x 1200 px).
              Pilih foto barang atau tarik dan letakkan hingga 5 foto sekaligus di sini. Upload min. 1 foto yang menarik untuk menarik perhatian jastiper.
            </Typography>
            
            <Grid container spacing={2}>
              {/* Main Photo */}
              <Grid item xs={12} sm={4}>
                <input
                  accept="image/*"
                  id="main-image-upload"
                  type="file"
                  name="images"
                  onChange={handleImageUpload}
                  style={{ display: 'none' }}
                />
                <label htmlFor="main-image-upload" style={{ width: '100%', cursor: 'pointer' }}>
                  <Paper 
                    variant="outlined" 
                    sx={{ 
                      p: 1, 
                      height: 200, 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center',
                      position: 'relative',
                      bgcolor: !selectedFiles[0] ? '#f5f5f5' : 'transparent',
                      cursor: 'pointer',
                      '&:hover': {
                        bgcolor: !selectedFiles[0] ? '#eeeeee' : 'transparent'
                      }
                    }}
                  >
                    {selectedFiles[0] ? (
                      <>
                        <img 
                          src={URL.createObjectURL(selectedFiles[0])} 
                          alt="Main" 
                          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                        />
                        <IconButton
                          size="small"
                          sx={{ position: 'absolute', top: 8, right: 8 }}
                          onClick={(e) => {
                            e.preventDefault();
                            removeImage(0);
                          }}
                        >
                          <Close />
                        </IconButton>
                      </>
                    ) : uploadingImages[0] ? (
                      <Box textAlign="center">
                        <CircularProgress size={24} sx={{ mb: 1 }} />
                        <Typography variant="body2" color="text.secondary">
                          Mengunggah foto...
                        </Typography>
                      </Box>
                    ) : (
                      <Box textAlign="center">
                        <Typography variant="body2" color="text.secondary">
                          Foto Utama
                        </Typography>
                        <PhotoCamera color="primary" />
                      </Box>
                    )}
                  </Paper>
                </label>
              </Grid>

              {/* Additional Photos - in a row */}
              <Grid item xs={12} sm={8}>
                <Grid container spacing={2}>
                  {[1, 2, 3, 4].map((index) => (
                    <Grid item xs={6} sm={3} key={index}>
                      <input
                        accept="image/*"
                        id={`image-upload-${index}`}
                        type="file"
                        name="images"
                        onChange={handleImageUpload}
                        style={{ display: 'none' }}
                      />
                      <label htmlFor={`image-upload-${index}`} style={{ width: '100%', cursor: 'pointer' }}>
                        <Paper 
                          variant="outlined" 
                          sx={{ 
                            p: 1, 
                            height: 90,
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            position: 'relative',
                            bgcolor: !selectedFiles[index] ? '#f5f5f5' : 'transparent',
                            cursor: 'pointer',
                            '&:hover': {
                              bgcolor: !selectedFiles[index] ? '#eeeeee' : 'transparent'
                            }
                          }}
                        >
                          {selectedFiles[index] ? (
                            <>
                              <img 
                                src={URL.createObjectURL(selectedFiles[index])} 
                                alt={`Photo ${index + 1}`} 
                                style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                              />
                              <IconButton
                                size="small"
                                sx={{ position: 'absolute', top: 4, right: 4 }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  removeImage(index);
                                }}
                              >
                                <Close fontSize="small" />
                              </IconButton>
                            </>
                          ) : uploadingImages[index] ? (
                            <Box textAlign="center">
                              <CircularProgress size={20} />
                            </Box>
                          ) : (
                            <Box textAlign="center">
                              <PhotoCamera color="primary" fontSize="small" />
                            </Box>
                          )}
                        </Paper>
                      </label>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel>Rute</InputLabel>
            <Select
              value={formData.route}
              onChange={(e) => {
                const route = e.target.value;
                setFormData(prev => ({
                  ...prev,
                  route,
                  departureCity: '',
                  arrivalCity: ''
                }));
              }}
              label="Rute"
            >
              <MenuItem value="indonesia-australia">Indonesia ke Australia</MenuItem>
              <MenuItem value="australia-indonesia">Australia ke Indonesia</MenuItem>
            </Select>
          </FormControl>

          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Kota Asal</InputLabel>
                <Select
                  value={formData.departureCity}
                  onChange={(e) => handleInputChange('departureCity', e.target.value)}
                  label="Kota Asal"
                >
                  {(formData.route === 'indonesia-australia' ? INDONESIA_CITIES : AUSTRALIA_CITIES).map(city => (
                    <MenuItem key={city} value={city}>{city}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Kota Tujuan</InputLabel>
                <Select
                  value={formData.arrivalCity}
                  onChange={(e) => handleInputChange('arrivalCity', e.target.value)}
                  label="Kota Tujuan"
                >
                  {(formData.route === 'indonesia-australia' ? AUSTRALIA_CITIES : INDONESIA_CITIES).map(city => (
                    <MenuItem key={city} value={city}>{city}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Berat Barang (KG)</InputLabel>
                <Select
                  value={formData.weight}
                  onChange={(e) => handleInputChange('weight', Number(e.target.value))}
                  label="Berat Barang (KG)"
                  required
                >
                  <MenuItem value={1}>1KG atau dibawah</MenuItem>
                  {Array.from({ length: 19 }, (_, i) => i + 2).map((num) => (
                    <MenuItem key={num} value={num}>
                      {num} KG
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <Box sx={{ mt: 1, mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.2 }}>
                  <Typography variant="subtitle1" sx={{ mr: 1 }}>
                    Total Biaya:
                  </Typography>
                  <Typography variant="h6" color="primary">
                    {formatPrice(calculatePrice())}
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary" sx={{ mt: -0.5 }}>
                  Bayar langsung ke traveller
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <TextField
            fullWidth
            type="date"
            label="Batas Waktu"
            value={formData.expiresAt}
            onChange={(e) => handleInputChange('expiresAt', e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 3 }}
          />

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
          >
            {loading ? 'Membuat...' : 'Buat Permintaan'}
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default CreateShopperRequestAd;
