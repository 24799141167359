import axios from 'axios';

interface ImageResponse {
  success: boolean;
  message: string;
  data: {
    images: Array<{
      url: string;
    }>;
  };
}

export const uploadImages = async (files: File[], token: string) => {
  const formData = new FormData();
  files.forEach(file => {
    formData.append('images', file);
  });

  try {
    const response = await axios.post<ImageResponse>('https://file.mivo.com/images/bagasi/upload', formData, {
      headers: {
        'X-API-Key': process.env.REACT_APP_BAGASI_API_KEY || '',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    if (!response.data.success || !response.data.data?.images) {
      console.error('Invalid response format:', response.data);
      throw new Error(response.data.message || 'Failed to upload images');
    }

    // Extract URLs from the response
    return response.data.data.images.map(img => img.url);
  } catch (error: any) {
    console.error('Image upload error:', error.response?.data || error.message);
    throw error;
  }
};
