import React, { useState, useEffect } from 'react';
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
  Switch,
} from '@mui/material';
import { adminApi } from '../../services/api';
import { toast } from 'react-toastify';
import { formatCurrency } from '../../utils/currency';

interface User {
  _id: string;
  username: string;
  email: string;
  whatsappNumber: string;
}

interface ShopperRequestAd {
  _id: string;
  itemTypes: string[];
  additionalNotes: string;
  images: string[];
  weight: number;
  departureCity: string;
  arrivalCity: string;
  route: string;
  currency: string;
  pricePerKg: number;
  totalPrice: number;
  createdAt: string;
  expiresAt: string;
  status: string;
  user: User;
}

const ShopperRequestManagement: React.FC = () => {
  const [ads, setAds] = useState<ShopperRequestAd[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchAds = async () => {
    try {
      const response = await adminApi.getShopperRequestAds();
      setAds(response || []);
    } catch (error) {
      console.error('Error fetching ads:', error);
      toast.error('Failed to fetch shopper request ads');
      setAds([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAds();
  }, []);

  const handleStatusChange = async (adId: string, active: boolean) => {
    try {
      await adminApi.updateShopperRequestAdStatus(adId, active);
      toast.success(`Ad ${active ? 'activated' : 'deactivated'} successfully`);
      fetchAds();
    } catch (error) {
      console.error('Error updating ad status:', error);
      toast.error('Failed to update ad status');
      fetchAds(); // Refresh to get current state
    }
  };

  const isAdExpired = (ad: ShopperRequestAd) => {
    const now = new Date();
    return new Date(ad.expiresAt) <= now;
  };

  const getAdStatus = (ad: ShopperRequestAd) => {
    if (isAdExpired(ad)) return 'Expired';
    return ad.status === 'active' ? 'Active' : 'Inactive';
  };

  const getStatusColor = (ad: ShopperRequestAd) => {
    if (isAdExpired(ad)) return 'error';
    return ad.status === 'active' ? 'success' : 'default';
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('id-ID', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Shopper Request Management
      </Typography>

      {loading ? (
        <Box display="flex" justifyContent="center" m={3}>
          <CircularProgress />
        </Box>
      ) : ads.length === 0 ? (
        <Typography variant="body1" align="center" m={3}>
          No shopper request ads found
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell>Item Types</TableCell>
                <TableCell>Route</TableCell>
                <TableCell>Price/Kg</TableCell>
                <TableCell>Total Price</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Expires</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ads.map((ad) => (
                <TableRow key={ad._id}>
                  <TableCell>
                    <Typography variant="body2">{ad.user?.username || 'N/A'}</Typography>
                    <Typography variant="caption" color="textSecondary">
                      {ad.user?.whatsappNumber || 'No WhatsApp'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {ad.itemTypes?.map((type) => (
                      <Chip key={type} label={type} size="small" sx={{ m: 0.5 }} />
                    ))}
                  </TableCell>
                  <TableCell>
                    {ad.departureCity} → {ad.arrivalCity}
                    <Typography variant="caption" display="block" color="textSecondary">
                      {ad.route}
                    </Typography>
                  </TableCell>
                  <TableCell>{formatCurrency(ad.pricePerKg, ad.currency)}</TableCell>
                  <TableCell>{formatCurrency(ad.totalPrice, ad.currency)}</TableCell>
                  <TableCell>{formatDate(ad.createdAt)}</TableCell>
                  <TableCell>{formatDate(ad.expiresAt)}</TableCell>
                  <TableCell>
                    <Switch
                      checked={!isAdExpired(ad) && ad.status === 'active'}
                      onChange={(e) => handleStatusChange(ad._id, e.target.checked)}
                      disabled={isAdExpired(ad)}
                      color={getStatusColor(ad)}
                    />
                    {getAdStatus(ad)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default ShopperRequestManagement;
