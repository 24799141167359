import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Alert,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Checkbox,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import AdPostingConfirmation from '../components/AdPostingConfirmation';

const AUSTRALIAN_CITIES = [
  { value: 'divider-au', label: '--- AUSTRALIA ---', disabled: true },
  { value: 'sydney', label: 'Sydney' },
  { value: 'melbourne', label: 'Melbourne' },
  { value: 'brisbane', label: 'Brisbane' },
  { value: 'perth', label: 'Perth' },
  { value: 'adelaide', label: 'Adelaide' },
  { value: 'goldcoast', label: 'Gold Coast' },
  { value: 'canberra', label: 'Canberra' },
  { value: 'newcastle', label: 'Newcastle' },
  { value: 'wollongong', label: 'Wollongong' },
  { value: 'hobart', label: 'Hobart' },
  { value: 'darwin', label: 'Darwin' }
];

const INDONESIAN_CITIES = [
  { value: 'divider-id', label: '--- INDONESIA ---', disabled: true },
  { value: 'jakarta', label: 'Jakarta' },
  { value: 'surabaya', label: 'Surabaya' },
  { value: 'medan', label: 'Medan' },
  { value: 'bandung', label: 'Bandung' },
  { value: 'semarang', label: 'Semarang' },
  { value: 'makassar', label: 'Makassar' },
  { value: 'palembang', label: 'Palembang' },
  { value: 'tangerang', label: 'Tangerang' },
  { value: 'depok', label: 'Depok' },
  { value: 'yogyakarta', label: 'Yogyakarta' },
  { value: 'denpasar', label: 'Denpasar' }
];

const MALAYSIAN_CITIES = [
  { value: 'divider-my', label: '--- MALAYSIA ---', disabled: true },
  { value: 'kualalumpur', label: 'Kuala Lumpur' },
  { value: 'penang', label: 'Penang' },
  { value: 'johor', label: 'Johor Bahru' },
  { value: 'malacca', label: 'Malacca' }
];

const SINGAPORE_CITIES = [
  { value: 'divider-sg', label: '--- SINGAPURA ---', disabled: true },
  { value: 'singapore', label: 'Singapore' }
];

const JAPANESE_CITIES = [
  { value: 'divider-jp', label: '--- JEPANG ---', disabled: true },
  { value: 'tokyo', label: 'Tokyo' },
  { value: 'osaka', label: 'Osaka' },
  { value: 'fukuoka', label: 'Fukuoka' },
  { value: 'sapporo', label: 'Sapporo' }
];

const SAUDI_CITIES = [
  { value: 'divider-sa', label: '--- ARAB SAUDI ---', disabled: true },
  { value: 'jeddah', label: 'Jeddah' },
  { value: 'riyadh', label: 'Riyadh' },
  { value: 'mekkah', label: 'Mekkah' },
  { value: 'madinah', label: 'Madinah' }
];

const KOREAN_CITIES = [
  { value: 'divider-kr', label: '--- KOREA SELATAN ---', disabled: true },
  { value: 'seoul', label: 'Seoul' },
  { value: 'busan', label: 'Busan' },
  { value: 'incheon', label: 'Incheon' },
  { value: 'jeju', label: 'Jeju' }
];

const TURKISH_CITIES = [
  { value: 'divider-tr', label: '--- TURKI ---', disabled: true },
  { value: 'istanbul', label: 'Istanbul' },
  { value: 'ankara', label: 'Ankara' },
  { value: 'antalya', label: 'Antalya' }
];

const THAI_CITIES = [
  { value: 'divider-th', label: '--- THAILAND ---', disabled: true },
  { value: 'bangkok', label: 'Bangkok' },
  { value: 'phuket', label: 'Phuket' },
  { value: 'chiangmai', label: 'Chiang Mai' }
];

const EUROPEAN_CITIES = [
  { value: 'divider-eu', label: '--- EROPA ---', disabled: true },
  { value: 'paris', label: 'Paris' },
  { value: 'london', label: 'London' },
  { value: 'amsterdam', label: 'Amsterdam' },
  { value: 'rome', label: 'Rome' },
  { value: 'berlin', label: 'Berlin' },
  { value: 'madrid', label: 'Madrid' },
  { value: 'barcelona', label: 'Barcelona' },
  { value: 'munich', label: 'Munich' },
  { value: 'milan', label: 'Milan' },
  { value: 'vienna', label: 'Vienna' },
  { value: 'prague', label: 'Prague' },
  { value: 'brussels', label: 'Brussels' },
  { value: 'frankfurt', label: 'Frankfurt' },
  { value: 'zurich', label: 'Zurich' },
  { value: 'venice', label: 'Venice' },
  { value: 'florence', label: 'Florence' },
  { value: 'copenhagen', label: 'Copenhagen' }
];

const CHINESE_CITIES = [
  { value: 'divider-cn', label: '--- CHINA ---', disabled: true },
  { value: 'shanghai', label: 'Shanghai' },
  { value: 'beijing', label: 'Beijing' },
  { value: 'guangzhou', label: 'Guangzhou' },
  { value: 'shenzhen', label: 'Shenzhen' },
  { value: 'chengdu', label: 'Chengdu' },
  { value: 'hangzhou', label: 'Hangzhou' },
  { value: 'nanjing', label: 'Nanjing' }
];

const US_CITIES = [
  { value: 'divider-us', label: '--- AMERIKA SERIKAT ---', disabled: true },
  { value: 'newyork', label: 'New York' },
  { value: 'losangeles', label: 'Los Angeles' },
  { value: 'chicago', label: 'Chicago' },
  { value: 'houston', label: 'Houston' },
  { value: 'phoenix', label: 'Phoenix' },
  { value: 'philadelphia', label: 'Philadelphia' },
  { value: 'sanantonio', label: 'San Antonio' },
  { value: 'sandiego', label: 'San Diego' },
  { value: 'dallas', label: 'Dallas' },
  { value: 'sanfrancisco', label: 'San Francisco' }
];

const MIDDLE_EAST_CITIES = [
  { value: 'divider-me', label: '--- TIMUR TENGAH ---', disabled: true },
  { value: 'dubai', label: 'Dubai' },
  { value: 'abudhabi', label: 'Abu Dhabi' },
  { value: 'doha', label: 'Doha' },
  { value: 'muscat', label: 'Muscat' }
];

const EGYPTIAN_CITIES = [
  { value: 'divider-eg', label: '--- MESIR ---', disabled: true },
  { value: 'cairo', label: 'Cairo' },
  { value: 'alexandria', label: 'Alexandria' },
  { value: 'giza', label: 'Giza' },
  { value: 'sharmelsheikh', label: 'Sharm El Sheikh' },
  { value: 'luxor', label: 'Luxor' }
];

const ALL_CITIES = [
  ...AUSTRALIAN_CITIES,
  ...INDONESIAN_CITIES,
  ...MALAYSIAN_CITIES,
  ...SINGAPORE_CITIES,
  ...JAPANESE_CITIES,
  ...SAUDI_CITIES,
  ...KOREAN_CITIES,
  ...TURKISH_CITIES,
  ...THAI_CITIES,
  ...EUROPEAN_CITIES,
  ...CHINESE_CITIES,
  ...US_CITIES,
  ...MIDDLE_EAST_CITIES,
  ...EGYPTIAN_CITIES
];

const CURRENCIES = [
  { code: 'AUD', label: 'Dollar Australia (AUD)' },
  { code: 'IDR', label: 'Rupiah Indonesia (IDR)' },
  { code: 'USD', label: 'Dollar Amerika (USD)' },
  { code: 'SGD', label: 'Dollar Singapura (SGD)' },
  { code: 'KRW', label: 'Won Korea (KRW)' },
  { code: 'JPY', label: 'Yen Jepang (JPY)' },
  { code: 'EUR', label: 'Euro (EUR)' },
  { code: 'RMB', label: 'Yuan China (RMB)' }
];

const getDefaultDates = () => {
  const today = new Date();
  
  // Default flight date (3 weeks from today)
  const flightDate = new Date(today);
  flightDate.setDate(today.getDate() + 21);
  
  // Default last drop date (3 days before flight date)
  const lastDropDate = new Date(flightDate);
  lastDropDate.setDate(flightDate.getDate() - 3);
  
  return {
    flightDate: flightDate.toISOString().split('T')[0],
    lastDropDate: lastDropDate.toISOString().split('T')[0],
  };
};

// Helper function to capitalize first letter of each word
const capitalizeWords = (str: string) => {
  return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
};

const validationSchema = yup.object({
  departureCity: yup.string()
    .required('Mohon pilih kota asal perjalanan Anda')
    .test('not-divider', 'Mohon pilih kota yang valid (bukan pembatas negara)', (value) => 
      !value?.includes('divider-'))
    .transform((value) => value ? capitalizeWords(value) : value),
  arrivalCity: yup.string()
    .required('Mohon pilih kota tujuan perjalanan Anda')
    .test('not-divider', 'Mohon pilih kota yang valid (bukan pembatas negara)', (value) => 
      !value?.includes('divider-'))
    .test('different-city', 'Kota tujuan harus berbeda dengan kota asal', function(value) {
      return value !== this.parent.departureCity;
    })
    .transform((value) => value ? capitalizeWords(value) : value),
  departureDate: yup.date().required('Mohon isi tanggal keberangkatan Anda'),
  returnDate: yup.date()
    .required('Mohon isi batas waktu pengantaran')
    .max(yup.ref('departureDate'), 'Batas pengantaran harus sebelum atau sama dengan tanggal keberangkatan'),
  availableWeight: yup.number()
    .required('Mohon isi berat bagasi yang tersedia')
    .positive('Berat bagasi harus lebih dari 0 KG')
    .max(32, 'Berat bagasi maksimal adalah 32 KG'),
  pricePerKg: yup.number()
    .required('Mohon isi harga per KG')
    .positive('Harga per KG harus lebih dari 0'),
  currency: yup.string().required('Mohon pilih mata uang'),
  additionalNotes: yup.string(),
  premium: yup.object().shape({
    highlight: yup.boolean(),
    showOnTop: yup.boolean().test(
      'requires-highlight',
      'Top Position memerlukan Highlight',
      function(showOnTop) {
        return !showOnTop || this.parent.highlight;
      }
    )
  }),
});

const AD_POSTING_FEE = 195000; // Rp 195.000

const CreateAd = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { flightDate, lastDropDate } = getDefaultDates();

  const formik = useFormik({
    initialValues: {
      departureCity: '',
      arrivalCity: '',
      departureDate: flightDate,
      returnDate: lastDropDate,
      availableWeight: '',
      pricePerKg: '',
      currency: 'AUD',
      additionalNotes: '',
      premium: {
        highlight: false,
        showOnTop: false
      },
    },
    validationSchema,
    onSubmit: async (values) => {
      setShowConfirmation(true);
    },
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('');
    setLoading(true);

    try {
      const formData = {
        departureCity: capitalizeWords(formik.values.departureCity),
        arrivalCity: capitalizeWords(formik.values.arrivalCity),
        departureDate: formik.values.departureDate,
        returnDate: formik.values.returnDate,
        availableWeight: Number(formik.values.availableWeight),
        pricePerKg: Number(formik.values.pricePerKg),
        currency: formik.values.currency,
        additionalNotes: formik.values.additionalNotes,
        premium: formik.values.premium,
      };

      // Validate form data
      try {
        await validationSchema.validate(formData, { abortEarly: false });
      } catch (validationError: any) {
        if (validationError.errors) {
          // Map technical errors to user-friendly messages
          const userFriendlyErrors = validationError.errors.map((err: string) => {
            if (err.includes('availableWeight must be a `number`')) {
              return 'Mohon isi berat bagasi yang tersedia';
            }
            if (err.includes('pricePerKg must be a `number`')) {
              return 'Mohon isi harga per KG';
            }
            return err;
          });
          // Format errors with bullet points
          setError(userFriendlyErrors.map((err: string) => `• ${err}`).join('\n'));
          return;
        }
      }

      // Navigate to payment page with ad details
      navigate('/ads/payment', {
        state: {
          adTitle: 'Jastip dari ' + formData.departureCity + ' ke ' + formData.arrivalCity,
          flightDate: formData.departureDate,
          adData: formData
        },
      });
    } catch (error) {
      console.error('Error creating ad:', error);
      setError('Terjadi kesalahan sistem. Mohon coba lagi.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Buka Jasa Titip Bagasi Baru - Bagasi.id";
    
    // Add meta tags for SEO
    const metaDescription = document.querySelector('meta[name="description"]') || document.createElement('meta');
    metaDescription.setAttribute('name', 'description');
    metaDescription.setAttribute('content', 'Buka jasa titip bagasi baru di Bagasi.id. Dapatkan penghasilan tambahan dengan menjadi jastiper bagasi. Daftar sekarang dan mulai menerima pesanan.');
    
    const metaKeywords = document.querySelector('meta[name="keywords"]') || document.createElement('meta');
    metaKeywords.setAttribute('name', 'keywords');
    metaKeywords.setAttribute('content', 'jasa titip bagasi, jastip bagasi, jastiper bagasi, bagasi.id, buka jasa titip, jastip murah');
    
    const metaOgTitle = document.querySelector('meta[property="og:title"]') || document.createElement('meta');
    metaOgTitle.setAttribute('property', 'og:title');
    metaOgTitle.setAttribute('content', 'Buka Jasa Titip Bagasi Baru - Bagasi.id');
    
    const metaOgDescription = document.querySelector('meta[property="og:description"]') || document.createElement('meta');
    metaOgDescription.setAttribute('property', 'og:description');
    metaOgDescription.setAttribute('content', 'Buka jasa titip bagasi baru di Bagasi.id. Dapatkan penghasilan tambahan dengan menjadi jastiper bagasi. Daftar sekarang dan mulai menerima pesanan.');
    
    const metaOgImage = document.querySelector('meta[property="og:image"]') || document.createElement('meta');
    metaOgImage.setAttribute('property', 'og:image');
    metaOgImage.setAttribute('content', 'https://market.bagasi.id/bagasi-og-image.jpg');
    
    const metaOgUrl = document.querySelector('meta[property="og:url"]') || document.createElement('meta');
    metaOgUrl.setAttribute('property', 'og:url');
    metaOgUrl.setAttribute('content', 'https://market.bagasi.id/ads/new');
    
    const metaTwitterCard = document.querySelector('meta[name="twitter:card"]') || document.createElement('meta');
    metaTwitterCard.setAttribute('name', 'twitter:card');
    metaTwitterCard.setAttribute('content', 'summary_large_image');
    
    // Add meta tags to head if they don't exist
    if (!document.querySelector('meta[name="description"]')) document.head.appendChild(metaDescription);
    if (!document.querySelector('meta[name="keywords"]')) document.head.appendChild(metaKeywords);
    if (!document.querySelector('meta[property="og:title"]')) document.head.appendChild(metaOgTitle);
    if (!document.querySelector('meta[property="og:description"]')) document.head.appendChild(metaOgDescription);
    if (!document.querySelector('meta[property="og:image"]')) document.head.appendChild(metaOgImage);
    if (!document.querySelector('meta[property="og:url"]')) document.head.appendChild(metaOgUrl);
    if (!document.querySelector('meta[name="twitter:card"]')) document.head.appendChild(metaTwitterCard);
  }, []);

  return (
    <Paper sx={{ p: 4, maxWidth: 600, mx: 'auto' }}>
      <Typography variant="h5" align="center" gutterBottom>
        Buka Jasa Titip Bagasi Baru
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2, whiteSpace: 'pre-line' }}>
          {error}
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <Box mb={3}>
          <FormControl fullWidth error={formik.touched.departureCity && Boolean(formik.errors.departureCity)}>
            <InputLabel>Dari</InputLabel>
            <Select
              name="departureCity"
              value={formik.values.departureCity}
              onChange={formik.handleChange}
              label="Dari"
            >
              {ALL_CITIES.map((city) => (
                <MenuItem 
                  key={city.value} 
                  value={city.value} 
                  disabled={city.disabled}
                  sx={city.disabled ? { 
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    fontWeight: 'bold',
                    color: 'text.secondary',
                    pointerEvents: 'none'
                  } : {}}
                >
                  {city.label}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.departureCity && formik.errors.departureCity && (
              <FormHelperText>{formik.errors.departureCity}</FormHelperText>
            )}
          </FormControl>
        </Box>

        <Box mb={3}>
          <FormControl fullWidth error={formik.touched.arrivalCity && Boolean(formik.errors.arrivalCity)}>
            <InputLabel>Ke</InputLabel>
            <Select
              name="arrivalCity"
              value={formik.values.arrivalCity}
              onChange={formik.handleChange}
              label="Ke"
            >
              {ALL_CITIES.map((city) => (
                <MenuItem 
                  key={city.value} 
                  value={city.value} 
                  disabled={city.disabled}
                  sx={city.disabled ? { 
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    fontWeight: 'bold',
                    color: 'text.secondary',
                    pointerEvents: 'none'
                  } : {}}
                >
                  {city.label}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.arrivalCity && formik.errors.arrivalCity && (
              <FormHelperText>{formik.errors.arrivalCity}</FormHelperText>
            )}
          </FormControl>
        </Box>

        <Box mb={3}>
          <TextField
            fullWidth
            name="departureDate"
            label="Tanggal Penerbangan"
            type="date"
            value={formik.values.departureDate}
            onChange={formik.handleChange}
            error={formik.touched.departureDate && Boolean(formik.errors.departureDate)}
            helperText={formik.touched.departureDate && formik.errors.departureDate}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        <Box mb={3}>
          <TextField
            fullWidth
            name="returnDate"
            label="Batas Pengantaran"
            type="date"
            value={formik.values.returnDate}
            onChange={formik.handleChange}
            error={formik.touched.returnDate && Boolean(formik.errors.returnDate)}
            helperText={formik.touched.returnDate && formik.errors.returnDate}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        <Box mb={3}>
          <TextField
            fullWidth
            name="availableWeight"
            label="Berat Tersedia (KG)"
            type="number"
            value={formik.values.availableWeight}
            onChange={formik.handleChange}
            error={formik.touched.availableWeight && Boolean(formik.errors.availableWeight)}
            helperText={formik.touched.availableWeight && formik.errors.availableWeight}
          />
        </Box>

        <Box mb={3} sx={{ display: 'flex', gap: 2 }}>
          <FormControl sx={{ minWidth: 120 }} error={formik.touched.currency && Boolean(formik.errors.currency)}>
            <InputLabel>Mata Uang</InputLabel>
            <Select
              name="currency"
              value={formik.values.currency}
              onChange={formik.handleChange}
              label="Mata Uang"
            >
              {CURRENCIES.map((currency) => (
                <MenuItem key={currency.code} value={currency.code}>
                  {currency.code}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.currency && formik.errors.currency && (
              <FormHelperText>{formik.errors.currency}</FormHelperText>
            )}
          </FormControl>
          <TextField
            fullWidth
            name="pricePerKg"
            required
            label="Harga per Kg"
            type="number"
            value={formik.values.pricePerKg}
            onChange={(e) => {
              const value = e.target.value;
              // Only allow positive numbers with up to 2 decimal places
              if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) > 0) {
                formik.setFieldValue('pricePerKg', value);
              }
            }}
            onKeyPress={(e) => {
              // Allow only numbers, backspace, and decimal point
              if (!/[\d.]/.test(e.key)) {
                e.preventDefault();
              }
              // Prevent multiple decimal points
              if (e.key === '.' && formik.values.pricePerKg.includes('.')) {
                e.preventDefault();
              }
            }}
            error={formik.touched.pricePerKg && Boolean(formik.errors.pricePerKg)}
            helperText={formik.touched.pricePerKg && formik.errors.pricePerKg}
            InputProps={{
              inputProps: { 
                min: 0.01,
                step: 0.01
              }
            }}
          />
        </Box>

        <Box mb={3}>
          <TextField
            fullWidth
            name="additionalNotes"
            label="Catatan Tambahan"
            multiline
            rows={4}
            value={formik.values.additionalNotes}
            onChange={formik.handleChange}
            error={formik.touched.additionalNotes && Boolean(formik.errors.additionalNotes)}
            helperText={formik.touched.additionalNotes && formik.errors.additionalNotes}
          />
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Premium Addons
          </Typography>
          <Paper variant="outlined" sx={{ p: 2, bgcolor: 'background.paper' }}>
            <FormControl component="fieldset" sx={{ width: '100%' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Paper 
                  variant="outlined" 
                  sx={{
                    p: 2,
                    borderColor: formik.values.premium.highlight ? 'primary.main' : 'divider',
                    bgcolor: formik.values.premium.highlight ? 'primary.lightest' : 'background.paper',
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                      borderColor: 'primary.main',
                      bgcolor: 'primary.lightest'
                    }
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 0.5 }}>
                        Highlight
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Jastip akan tampil dengan warna berbeda
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Typography variant="subtitle1" color="primary" sx={{ fontWeight: 'medium' }}>
                        Rp 50.000
                      </Typography>
                      <Checkbox
                        checked={formik.values.premium.highlight}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          formik.setFieldValue('premium.highlight', e.target.checked);
                          if (!e.target.checked) {
                            formik.setFieldValue('premium.showOnTop', false);
                          }
                        }}
                        color="primary"
                      />
                    </Box>
                  </Box>
                </Paper>

                <Paper 
                  variant="outlined" 
                  sx={{
                    p: 2,
                    borderColor: formik.values.premium.showOnTop ? 'primary.main' : 'divider',
                    bgcolor: formik.values.premium.showOnTop ? 'primary.lightest' : formik.values.premium.highlight ? 'background.paper' : 'action.disabledBackground',
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                      borderColor: formik.values.premium.highlight ? 'primary.main' : 'divider',
                      bgcolor: formik.values.premium.highlight ? 'primary.lightest' : 'action.disabledBackground'
                    },
                    opacity: formik.values.premium.highlight ? 1 : 0.7
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>
                      <Typography 
                        variant="subtitle1" 
                        sx={{ 
                          fontWeight: 'medium',
                          mb: 0.5,
                          color: !formik.values.premium.highlight ? 'text.disabled' : 'text.primary'
                        }}
                      >
                        Top Position
                      </Typography>
                      <Typography 
                        variant="body2" 
                        color={!formik.values.premium.highlight ? 'text.disabled' : 'text.secondary'}
                      >
                        Jastip akan muncul di urutan teratas
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Typography 
                        variant="subtitle1" 
                        color={formik.values.premium.highlight ? 'primary' : 'text.disabled'}
                        sx={{ fontWeight: 'medium' }}
                      >
                        Rp 50.000
                      </Typography>
                      <Checkbox
                        checked={formik.values.premium.showOnTop}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue('premium.showOnTop', e.target.checked)}
                        disabled={!formik.values.premium.highlight}
                        color="primary"
                      />
                    </Box>
                  </Box>
                </Paper>
              </Box>
              {formik.errors.premium?.showOnTop && (
                <FormHelperText error sx={{ mt: 1 }}>
                  {formik.errors.premium.showOnTop}
                </FormHelperText>
              )}
            </FormControl>
          </Paper>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            Biaya Pemasangan Iklan:
          </Typography>
          <Typography variant="h6" color="primary">
            Rp {(AD_POSTING_FEE + 
                (formik.values.premium.highlight ? 50000 : 0) + 
                (formik.values.premium.showOnTop ? 50000 : 0)
              ).toLocaleString('id-ID')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            *Termasuk biaya posting (Rp 195.000) dan premium addons
          </Typography>
        </Box>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={loading}
          sx={{ mt: 2 }}
        >
          {loading ? <CircularProgress size={24} /> : 'Bayar & Mulai Jastip'}
        </Button>
      </form>

      <AdPostingConfirmation
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={() => handleSubmit}
        adData={formik.values}
        postingFee={AD_POSTING_FEE}
      />
    </Paper>
  );
};

export default CreateAd;
